::-webkit-scrollbar {
  display: none;
}

* {
  font-family: "Hind Madurai", sans-serif;
  text-decoration-skip-ink: none;
}

%base-body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  font-family: -apple-system, BlinkMacSystemFont, "Hind Madurai",
    "Titillium Web", Marcellus, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @extend %base-body;

  background: var(--var-main-color);
  background: radial-gradient(
    circle,
    rgb(83 89 117 / 100%) 0%,
    rgb(38 38 45 / 100%) 46%,
    rgb(12 13 13 / 100%) 100%
  );

  --var-main-color: rgb(12 13 13 / 100%);
  --var-subtitle: rgb(209 209 209);
  --var-contrast-texts-and-logos: rgb(220 220 220);
  --var-link-color: rgb(118 118 255);
  --var-subblock-image-brightness: 0.8;
  --var-container-color: rgb(123 142 155);
  --var-block-link: rgb(22 22 139);

  background-attachment: fixed;
  background-repeat: no-repeat;
}

body.light {
  @extend %base-body;

  background: var(--var-main-color);
  background: radial-gradient(
    circle,
    rgb(107 226 183 / 100%) 0%,
    rgb(237 255 246 / 100%) 50%,
    rgb(253 255 253 / 100%) 65%,
    rgb(255 255 255 / 100%) 100%
  );

  --var-main-color: white;
  --var-subtitle: rgb(85 85 85);
  --var-contrast-texts-and-logos: black;
  --var-link-color: blue;
  --var-subblock-image-brightness: 0.6;
  --var-container-color: rgb(160 187 175);
  --var-block-link: rgb(54 54 167);

  background-attachment: fixed;
  background-repeat: no-repeat;
}

h2 {
  color: var(--var-contrast-texts-and-logos);
  font-weight: 500;
}

.wrapper {
  align-items: center;
  min-height: 100%;
}

%button {
  position: fixed;
  display: flex;
  flex-direction: row;
  height: 60px;
  margin: 0 20px;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -2px;
  line-height: calc(25px + 1vw);
  cursor: pointer;
  color: var(--var-contrast-texts-and-logos);
  z-index: 1;
}

.up-button {
  @extend %button;
}

.toggler-button {
  @extend %button;

  background: none;
  position: absolute;
  right: 0;
  padding: 20px;
}

html {
  scroll-behavior: smooth;
}
