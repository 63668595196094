.root {
  position: static;
  top: 0;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background: var(--var-main-color);
  background: linear-gradient(
    0deg,
    rgb(255 255 255 / 0%) 25%,
    75%,
    var(--var-main-color) 100%
  );
  z-index: 1;
}

.logo-box {
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
  padding: 20px 0 40px;
}

.image-logo {
  height: calc(max(35px + 2vw, 80px));
}

.test-logo {
  align-items: center;
  font-size: calc(35px + 1vw);
  font-weight: 400;
  letter-spacing: calc(-3px - 0.1vw);
  line-height: calc(25px + 1vw);
  margin: 0;
  padding: 0 5px;
  text-transform: uppercase;
  color: var(--var-contrast-texts-and-logos);
}

@media (width <= 500px) {
  .logo-box {
    padding: 20px 0;
  }

  .test-logo {
    font-size: 0;
    opacity: 0;
  }
}
