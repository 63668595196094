@import "../styleConstants";

.link-box {
  display: flex;
  padding: 10px;
  justify-content: center;
  gap: 20px;
}

.button-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  gap: 15px;
}

.link {
  color: var(--var-block-link);
  text-decoration: underline 2px;
  font-size: calc(15px + 0.3vw);
  text-align: center;
  transition: 1s;
}

.button {
  @extend .link;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 100px;
  font-size: 22px;
  height: 40px;
  padding: 0 20px;
  background: var(--var-container-color);
  border-radius: 5px;
  transition: 0.5s;
}

.button-pressed {
  @extend .button;

  filter: saturate(3) brightness(0.7);
}

.link:hover {
  transition: 1s;
  font-size: calc(15px + 0.4vw);
  cursor: pointer;
}

.button:hover {
  transition: 1s;
  font-size: 26px;
  cursor: pointer;
}

.block-container {
  margin-bottom: 20px;
  border: black 2px solid;
  border-left: black 4px solid;
  box-sizing: border-box;
  background: var(--var-container-color);
  overflow-wrap: break-word;
  padding: 10px 20px;
  border-radius: 0 5px 5px 0;
}

.block-title {
  font-size: 25px;
  text-decoration: underline 2px;
  text-underline-offset: 4px;
}

.block-title:link {
  color: var(--var-block-link);
}

.block-title:visited {
  color: var(--var-block-link);
}

.block-text {
  font-size: 20px;
  margin: 5px 0 20px;
  min-height: 0;
  font-weight: 300;
}

.block-text img {
  max-height: 300px;
  max-width: 100%;
}

.page-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 40px;
  color: var(--var-subtitle);
  border-bottom: solid var(--var-subtitle) 3px;
  letter-spacing: -2px;
  margin: 5px 0 30px;
  font-family: "Marcellus SC", serif;
  line-height: 98%;
  text-transform: uppercase;
  font-weight: 400;
}

.page-subtitle:hover {
  font-size: 34px;
  transition: 1s;
}

.page-description {
  @extend .block-text;

  color: var(--var-contrast-texts-and-logos);
  margin-bottom: 30px;
  max-width: 100%;
}

.page-description img {
  max-width: 100%;
  max-height: 90vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pagination-box {
  display: flex;
  justify-content: center;
}

.dark {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 90%);
  align-items: center;
  justify-content: center;
}

@media (max-width: $mobile-width) {
  .button-box {
    display: block;
    columns: auto;
    column-count: 2;
  }

  .button {
    margin-bottom: 5px;
    break-inside: avoid;
  }
}
