.img {
  object-fit: cover;
  image-rendering: auto;
  aspect-ratio: 1;
}

.img-hidden {
  display: none;
}

.img:hover {
  cursor: pointer;
}

.carousel-box {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: stretch;
  justify-content: center;
  height: 90vh;
  width: 90vw;
}

.carousel-middle-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}

.carousel-side-panel {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carousel-side-panel-box {
  align-items: start;
}

.carousel-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.carousel-image {
  max-height: 70vh;
  max-width: 100%;
  display: block;
}

.carousel-description {
  border-top: solid white 2px;
  width: 30%;
  padding-top: 10px;
  margin-bottom: 20px;
  color: white;
}

.carousel-text {
  max-width: 100%;
  font-size: 16px;
  text-align: center;
}

@media (width <= 850px) {
  .carousel-box {
    width: 100%;
  }

  .carousel-image-box {
    padding: 0 0 2vh;
  }

  .carousel-description {
    width: 90%;
  }
}
